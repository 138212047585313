import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  inject,
  PLATFORM_ID,
  provideZoneChangeDetection,
} from '@angular/core';
import { RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';
import {
  provideRouter,
  TitleStrategy,
  withRouterConfig,
} from '@angular/router';

import { routes } from './app.routes';
import {
  provideClientHydration,
  withEventReplay,
} from '@angular/platform-browser';

import {
  HttpInterceptorFn,
  provideHttpClient,
  withFetch,
  withInterceptors,
} from '@angular/common/http';
import { AppInitService } from './AppInitService';
import { SharedModule } from './shared/shared.module';
import { BreadCrumbComponent } from './layout/bread-crumb/bread-crumb.component';
import { API_BASE_URL } from '../api/Client';
import { provideToastr } from 'ngx-toastr';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import { environment } from '../environments/environment';
import { UmbracoTitleStrategy } from './core/UmbracoTitleStrategy';
import { isPlatformBrowser } from '@angular/common';
import {
  provideShareButtonsOptions,
  SharerMethods,
  withConfig,
} from 'ngx-sharebuttons';
import { shareIcons } from 'ngx-sharebuttons/icons';

export const languageInterceptor: HttpInterceptorFn = (req, next) => {
  if (isPlatformBrowser(inject(PLATFORM_ID))) {
    var currentLang = localStorage.getItem('current-lang');
    if (currentLang == null) {
      currentLang = 'ar';
    }
    const modifiedReq = req.clone({
      headers: req.headers.set('accept-lang', currentLang),
    });
    return next(modifiedReq);
  } else {
    const modifiedReq = req.clone({
      headers: req.headers.set('accept-lang', 'ar'),
    });
    return next(modifiedReq);
  }
};

export const previewInterceptor: HttpInterceptorFn = (req, next) => {
  if (isPlatformBrowser(inject(PLATFORM_ID))) {
    const urlParams = new URLSearchParams(window.location.search);
    const myParam = urlParams.get('preview');
    const modifiedReq = req.clone({
      headers: req.headers.set(
        'is-preview',
        myParam == null ? 'false' : myParam
      ),
    });
    return next(modifiedReq);
  } else {
    return next(req);
  }
};

export const appConfig: ApplicationConfig = {
  providers: [
    BrowserAnimationsModule,
    provideToastr(),
    provideAnimations(),
    provideShareButtonsOptions(
      shareIcons(),
      withConfig({
        debug: true,
        sharerMethod: SharerMethods.Anchor,
      })
    ),
    SharedModule,
    BreadCrumbComponent,
    provideHttpClient(
      withInterceptors([languageInterceptor, previewInterceptor])
    ), //, withFetch()
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always',
        onSameUrlNavigation: 'reload',
      })
    ),
    provideClientHydration(withEventReplay()),
    { provide: API_BASE_URL, useValue: environment.apis.default.url },
    {
      provide: 'GOOGLE_MAPS_API_KEY', // Custom Injection Token for Google Maps API key
      useValue: environment.googleMapsApiKey, // Set your Google Maps API key here
    },
    // {
    //   provide: APP_INITIALIZER,
    //   useFactory: initializeApp,
    //   deps: [AppInitService],
    //   multi: true,
    // },
    { provide: TitleStrategy, useClass: UmbracoTitleStrategy },
  ],
};

export function initializeApp(
  appInitService: AppInitService
): () => Promise<void> {
  return () => appInitService.init();
}
