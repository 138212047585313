import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Route, Router, Routes } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { AboutUsComponent } from './umbraco/about-us/about-us.component';
import { GrowthProjectsComponent } from './umbraco/growth-projects/growth-projects.component';
import { ContactUsComponent } from './umbraco/contact-us/contact-us.component';
import { GrowthProjectComponent } from './umbraco/growth-project/growth-project.component';
import { isPlatformServer } from '@angular/common';
import { ContentDeliveryInformationService } from '../api/Client';
import { TanmiahNewsComponent } from './umbraco/tanmiah-news/tanmiah-news.component';
import { TanmiahNewsDetailsComponent } from './umbraco/tanmiah-news-details/tanmiah-news-details.component';
import { IdentityVisionComponent } from './umbraco/identity-vision/identity-vision.component';
import { GalleriaContent } from 'primeng/galleria';
import { GalleryComponent } from './umbraco/gallery/gallery.component';
import { PoliciesComponent } from './umbraco/policies/policies.component';
import { TermsConditionsComponent } from './umbraco/terms-conditions/terms-conditions.component';
import { SiteMapComponent } from './umbraco/site-map/site-map.component';
import { SuppliersComponent } from './umbraco/suppliers/suppliers.component';
import { FrequentQuestionsComponent } from './umbraco/frequent-questions/frequent-questions.component';
import { HiringComponent } from './umbraco/hiring/hiring.component';

@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  configRoutes: Routes;
  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private Router: Router,
    private Client: ContentDeliveryInformationService
  ) {
    this.configRoutes = this.Router.config;
  }

  contentTypeComponentMapping = {
    // home: HomePageContentComponent,
    aboutTanmiah: AboutUsComponent,
    developmentProjects: GrowthProjectsComponent,
    contactUs: ContactUsComponent,
    developmentProjectItem: GrowthProjectComponent,
    news: TanmiahNewsComponent,
    newsItem: TanmiahNewsDetailsComponent,
    visualIdentity: IdentityVisionComponent,
    mediaLibrary: GalleryComponent,
    privacyPolicy: PoliciesComponent,
    termsAndConditions: TermsConditionsComponent,
    siteMap: SiteMapComponent,
    suppliersPortal: SuppliersComponent,
    faqs: FrequentQuestionsComponent,
    joinUs:HiringComponent
  };

  traversePages(pages: any): void {
    pages.forEach((page: any) => {
      var item = {
        path: page.url.replace(/^\//, ''),
        component:
          this.contentTypeComponentMapping[
            page.contentType as keyof typeof this.contentTypeComponentMapping
          ], // Type casting fix
      };

      if (item.component !== undefined) this.configRoutes.unshift(item);
      // If the page has children, recursively traverse them
      if (page.children && page.children.length > 0) {
        this.traversePages(page.children);
      }
    });
  }

  init(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (isPlatformServer(this.platformId)) resolve({});
      else
        this.Client.nodesRoutes().subscribe({
          next: (response) => {
            var paths = response.map((x) => {
              return {
                name: x.name,
                url: x.url,
                contentType: x.cotnentType,
                children: [],
              };
            });
            this.traversePages(paths);
            this.Router.resetConfig(this.configRoutes);

            resolve(this.configRoutes);
          },
          error: () => {
            reject();
          },
        });
    });
  }
}
