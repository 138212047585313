import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { NewsFilterComponent } from '../../components/tanmiah-news/news-filter/news-filter.component';
import { PageTitleInfoComponent } from '../../components/shared/page-title-info/page-title-info.component';
import { environment } from '../../../environments/environment';
import {
  CommonContentService,
  IntroDto,
  NewsPageContentService,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { PageRatingComponent } from '../../components/shared/page-rating/page-rating.component';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-tanmiah-news',
  templateUrl: './tanmiah-news.component.html',
  standalone: true,
  imports: [NewsFilterComponent, PageTitleInfoComponent, PageRatingComponent],
  styleUrl: './tanmiah-news.component.scss',
  animations: [fadeInAnimation],
})
export class TanmiahNewsComponent implements OnInit, OnDestroy {
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public IntroDto!: IntroDto;
  constructor(
    private _contentPagesServices: ContentPagesServices,
    private _client: NewsPageContentService,
    private _common: CommonContentService,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}

  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    this._common.introText(currentPagePath).subscribe((introDto) => {
      this.IntroDto = introDto;
      this._cotnentLoadingService.setLoadingState(true);
    });
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
