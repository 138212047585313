<div
  class="regular-news-list row"
  appListAnimation
  listClassName="sub-news-sec"
>
  @if (newsItemDto) {
    @for (item of newsItemDto; track $index) {
  <div class="col-lg-4 p-4 p-lg-3 p-xl-0">
    <div class="sub-news-sec list-item-hide">
      <img [ngSrc]="MediaUrl + item.image?.src" width="326" height="326" [alt]="" class="news-img" />
      <div class="news-info">
        <div class="news-tags">
          <!-- <span class="news-category">{{ item.category?.name }}</span> -->
          <div class="news-date">
            <span class="date-icon-green"></span>
            {{ item.date | date : "dd/MM/yyyy" }}
          </div>
        </div>
        <div class="news-desc">
          <span class="news-title">{{ item.title }}</span>
          <span class="news-text">{{ item.description }}​​</span>
        </div>
          <button class="btn more-news" [routerLink]="item.clickToAction?.url">
            {{ 'more' | umbracoTranslate | async }}
            <span class="arrow-more"></span>
          </button>
      </div>
    </div>
  </div>
  } }
  @if (newsItemDto.length === 0){
    <div style="text-align: center;">  {{ "sorry,noResults" | umbracoTranslate | async }}</div>

  }
</div>
