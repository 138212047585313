import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { filter, Subscription } from 'rxjs';
import {
  RecaptchaFormsModule,
  RecaptchaV3Module,
  ReCaptchaV3Service,
} from 'ng-recaptcha';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet, HeaderComponent, FooterComponent],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  private _currentLanguage: string | null = 'ar';
  routerSubscription!: Subscription;
  constructor(
    private renderer: Renderer2,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: any,

  ) {}
  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (localStorage.getItem('current-lang') === null) {
        localStorage.setItem('current-lang', 'ar');
      }
      this._currentLanguage = localStorage.getItem('current-lang') ?? 'ar';
      const htmlElement = document.documentElement;
      const bodyElement = document.body;

      if (this._currentLanguage == 'ar') {
        this.renderer.setAttribute(htmlElement, 'lang', 'ar');
        this.renderer.setAttribute(htmlElement, 'dir', 'rtl');
        this.renderer.setAttribute(bodyElement, 'dir', 'rtl');
      }
      if (this._currentLanguage == 'en-US') {
        this.renderer.setAttribute(htmlElement, 'lang', 'en-US');
        this.renderer.setAttribute(htmlElement, 'dir', 'ltr');
        this.renderer.setAttribute(bodyElement, 'dir', 'ltr');
      }

      this.routerSubscription = this.router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(() => {
          if (typeof window != 'undefined') window.scrollTo(0, 0); // Scroll to top on navigation end
        });
    }
  }
}
