import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { CommonContentService, SeoDto } from '../../api/Client';
import { environment } from '../../environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class UmbracoTitleStrategy extends TitleStrategy {
  public seoDto: SeoDto | null = null;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  constructor(
    private readonly title: Title,
    private metaData: Meta,
    private _client: CommonContentService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: any
  ) {
    super();
  }

  override updateTitle(routerState: RouterStateSnapshot) {
    if (isPlatformBrowser(this.platformId)) {
      var path = this.getPathForCurrentPage(routerState);
      this._client.sEOInfo(path).subscribe({
        next: (response) => {
          this.seoDto = response;
          this.title.setTitle(response.title ?? '');
        },
        complete: () => {
          this.updateSeoTags(this.seoDto!, routerState);
        },
      });
    }
  }

  private getPathForCurrentPage(routerState: RouterStateSnapshot) {
    return decodeURIComponent(
      routerState.url.replace('/ar/', '').replace('/en/', '')
    );
  }

  private updateSeoTags(seoDto: SeoDto, routerState: RouterStateSnapshot) {
    this.metaData.updateTag({
      name: 'title',
      content: this.seoDto?.title ?? '',
    });
    this.metaData.updateTag({
      name: 'description',
      content: this.seoDto?.description ?? '',
    });

    this.metaData.updateTag({
      name: 'keywords',
      content: this.seoDto?.metaTags?.join(',') ?? '',
    });
    this.metaData.updateTag({
      property: 'og:title',
      content: this.seoDto?.ogTitle ?? '',
    });

    this.metaData.updateTag({
      property: 'og:description',
      content: this.seoDto?.ogDescription ?? '',
    });

    this.metaData.updateTag({
      property: 'og:image',
      content:
        this.MediaUrl +
        (this.seoDto?.ogImage !== null ? this.seoDto?.ogImage?.src : ''),
    });

    this.metaData.updateTag({
      property: 'og:url',
      content: location.origin + decodeURIComponent(routerState.url),
    });

    this.metaData.updateTag({
      name: 'twitter:card',
      content: this.seoDto?.xCard ?? '',
    });

    this.metaData.updateTag({
      name: 'twitter:title',
      content: this.seoDto?.xTitle ?? '',
    });
    this.metaData.updateTag({
      name: 'twitter:description',
      content: this.seoDto?.xDescription ?? '',
    });
    this.metaData.updateTag({
      name: 'twitter:image',
      content:
        this.MediaUrl +
        (this.seoDto?.xImage !== null ? this.seoDto?.xImage?.src ?? '' : ''),
    });
    this.metaData.updateTag({
      name: 'twitter:image:alt',
      content:
        this.seoDto?.xImage !== null ? this.seoDto?.xImage?.name ?? '' : '',
    });
  }
}
