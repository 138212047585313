import {
  CommonModule,
  DatePipe,
  isPlatformBrowser,
  NgOptimizedImage,
} from '@angular/common';
import {
  Component,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { ListAnimationDirective } from '../../core/pipes/list-animation.directive';
import { GalleriaModule } from 'primeng/galleria';
import {
  CommonContentService,
  IntroDto,
  LookupDto,
  LookupsContentService,
  MediaStorePageContentService,
} from '../../../api/Client';
import { environment } from '../../../environments/environment';
import { FormsModule } from '@angular/forms';
import { UmbracoTranslatePipe } from '../../core/pipes/umbraco-translate.pipe';
import { MediaType } from '../../core/enums/media-types';
import { TranslationService } from '../../services/translation.service';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { debounceTime, fromEvent, map } from 'rxjs';
import { PageTitleInfoComponent } from '../../components/shared/page-title-info/page-title-info.component';
import { PageRatingComponent } from '../../components/shared/page-rating/page-rating.component';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';
import { SanitizedHtmlPipe } from '../../core/pipes/sanitized-html.pipe';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-gallery',
  standalone: true,
  imports: [
    ListAnimationDirective,
    CommonModule,
    GalleriaModule,
    FormsModule,
    UmbracoTranslatePipe,
    PageTitleInfoComponent,
    NgOptimizedImage,
    PageRatingComponent,
    SanitizedHtmlPipe,
    DatePipe,
  ],
  providers:[DatePipe],
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss'],
  animations: [fadeInAnimation],
})
export class GalleryComponent implements OnInit, OnDestroy {
  @ViewChild('debounceInput', { static: true }) debounceInput!: ElementRef;

  images = [
    {
      itemImageSrc: '/assets/images/img_carsoul.jpg',
      thumbnailImageSrc: '/assets/images/img_carsoul.jpg',
      alt: 'Description for Image 1',
      title: 'Title 1',
    },
    {
      itemImageSrc: '/assets/images/img_carsoul_1.png',
      thumbnailImageSrc: '/assets/images/img_carsoul_1.png',
      alt: 'Description for Image 1',
      title: 'Title 1',
    },
  ]; // Gallery IDs
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public GalleryTypeDto: LookupDto[] = [];
  public TotalCount: number = 0;
  public CurrentCount: number = 0;
  public SearchTerm: string = '';
  public galleryTypeId: string | null = null;
  public IntroDto!: IntroDto;
  imagesContainer: any = null;
  loadingDone: boolean = false;
  displayBasic: boolean = false;
  gallery: any = [];
  mediaTypes = MediaType;
  Loading: boolean = false;

  divStates: { [id: string]: boolean } = {};

  constructor(
    private _mediaStorePageContentService: MediaStorePageContentService,
    private _lokup: LookupsContentService,
    public _translationService: TranslationService,
    private _contentPagesServices: ContentPagesServices,
    private _common: CommonContentService,
    private spinner: NgxSpinnerService,
    private _cotnentLoadingService: CotnentLoadingService,
    public sanitized: DomSanitizer,
    private datePipe: DatePipe
  ) {}
  responsiveOptions: any[] = [
    {
      breakpoint: '1024px',
      numVisible: 5,
    },
    {
      breakpoint: '768px',
      numVisible: 3,
    },
    {
      breakpoint: '560px',
      numVisible: 1,
    },
  ];
  ngOnInit() {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    this.spinner.show();
    this.Loading = true;
    this._lokup.lookups('newsTypeItem').subscribe({
      next: async (response) => {
        this.GalleryTypeDto = response;
      },
      error: () => {
        this.spinner.hide();
      },
    });
    this._common.introText(currentPagePath).subscribe((res) => {
      this.IntroDto = res;
      this._cotnentLoadingService.setLoadingState(true);
    });

    this.SearchMedia(this.SearchTerm!, this.galleryTypeId!),
      fromEvent(this.debounceInput.nativeElement, 'input')
        .pipe(
          debounceTime(500), // half a sec
          map((event: any) => event.target.value)
        )
        .subscribe((value: string) => {
          this.Search({});
        });
  }

  isDefaultDate(date: Date): boolean {
    const formattedDate = this.datePipe.transform(date, 'dd/MM/yyyy');

    return formattedDate === '01/01/0001';
  }

  onOptionChange(event: any) {
    this.CurrentCount = 0;
    this.galleryTypeId = event.target.value;
    this.gallery = [];
    this.SearchMedia(this.SearchTerm!, this.galleryTypeId!);
  }
  Search(event: any) {
    this.CurrentCount = 0;
    this.gallery = [];
    this.SearchMedia(this.SearchTerm!, this.galleryTypeId!);
  }

  LoadMore(event: any) {
    this.SearchMedia(this.SearchTerm!, this.galleryTypeId!);
  }

  //projects(searchTerm?: string | undefined, regionId?: string | undefined, skipCount?: number | undefined, maxResultCount?: number | undefined)
  private SearchMedia(searchTerm: string, typeId: string | undefined) {
    this.Loading = true;
    this.spinner.show();
    this._mediaStorePageContentService
      .searchMediaStore(
        searchTerm!,
        typeId === 'null' ? '' : typeId ?? '',
        this.CurrentCount!,
        50
      )
      .subscribe({
        next: async (result) => {
          this.gallery = [...this.mapGalleryObject(result)];
          this.TotalCount = result.totalCount!;
          this.CurrentCount += result.items!.length;

          this.divStates = {};
          if (result.totalCount !== undefined)
            for (let i = 0; i <= result.totalCount; i++) {
              const id = `parent-div-${i}`;
              this.divStates[id] = true;
            }

          setTimeout(() => {
            //  this.showPartners();
          }, 500);
        },
        complete: () => {
          this.Loading = false;
          this.spinner.hide();
          window.scrollBy({ top: +50, behavior: 'smooth' });
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }
  showGallery(gallery: any) {
    gallery.display = true;
  }
  openVideo(url: string) {
    window.open(url, '_blank');
  }
  toggleDiv(index: string): void {
    this.divStates[index] = !this.divStates[index]; // Toggle state for the clicked div
  }
  generateId(index: number): string {
    return `parent-div-${index}`; // Generate unique ID for each div
  }

  mapGalleryObject(gallery: any) {
    return gallery.items
      ?.map((x: any) => {
        // Check if the type is 'mediaImage' and process accordingly
        if (x.type === 'mediaImage') {
          return {
            ...x,
            display: false,
            images: x.images?.map((y: any) => ({
              itemImageSrc: this.MediaUrl + y.src,
              thumbnailImageSrc: this.MediaUrl + y.src,
              alt: x.title,
              title: x.title,
            })),
            thumbnail: { src: x.images![0].src },
          };
        } else {
          // If it's not 'mediaImage', return the item unchanged
          return {
            ...x,
            safeurl: this.sanitized.bypassSecurityTrustResourceUrl(
              x.videoEmbed.toString().includes('umbraco-media')
                ? this.MediaUrl +
                    '/umbraco-media' +
                    x.videoEmbed.toString().split('umbraco-media')[1]
                : x.videoEmbed
            ),
          };
        }
      })
      .sort(
        (a: any, b: any) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
