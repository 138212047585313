import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { UmbracoTranslatePipe } from '../core/pipes/umbraco-translate.pipe';
@NgModule({
  declarations: [],
  imports: [CommonModule, FormsModule, UmbracoTranslatePipe],
  exports: [CommonModule, FormsModule, UmbracoTranslatePipe],
  providers: [],
})
export class SharedModule {}
