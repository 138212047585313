<app-page-title-info
  *ngIf="IntroDto"
  [BackgroundImage]="IntroDto?.image"
  [Title]="IntroDto?.title ?? ''"
  [Description]="IntroDto?.description ?? ''"
></app-page-title-info>

<div class="site-map-container" [@fadeIn]>
  <div class="content">
    <h1 class="title">{{ "internalPages" | umbracoTranslate | async }}</h1>
    <h3 class="sub-title">{{ "siteMap" | umbracoTranslate | async }}</h3>
    <div class="menu-list" appListAnimation listClassName="p-treenode">
      <p-tree
       [indentation]='5'
        [value]="nodes2"
        loadingMode="icon"
        (onNodeExpand)="onNodeExpand2($event)"
        (onNodeCollapse)="onNodeCollapse($event)"
      >
        <ng-template let-node pTemplate="url">
          <a
            [routerLink]="node.key"
            rel="noopener noreferrer"
            class="text-700 hover:text-primary"
            [class]="(!node.leaf || nodes2.indexOf(node) != -1)? 'map-label':''"
          >
            {{ node.label }}
          </a>
        </ng-template>
      </p-tree>

      <!-- <div *ngFor="let item of menuItems" class="menu-item-list list-item-hide">
        <div class="menu-item" (click)="toggleExpand(item)">
          <span class="icon">
            <img
              [src]="
                item.expanded
                  ? '/assets/images/minus_square.svg'
                  : '/assets/images/add_square.svg'
              "
              alt="Expand Icon"
              class="svg-icon"
            />
          </span>
          <span class="text">{{ item.title }}</span>
        </div>
        <ng-container *ngIf="item.expanded">
          <div class="submenu">
            <div class="submenu-item" *ngFor="let subItem of item.subItems">
              {{ subItem }}
            </div>
          </div>
        </ng-container>
      </div> -->
    </div>
  </div>
</div>
