import {
  CommonModule,
  isPlatformBrowser,
  NgOptimizedImage,
} from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  effect,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { NavigationExtras, Router, RouterModule } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import {
  CommonContentService,
  HeaderSectionDto,
  SearchResultDto,
  SliderDto,
} from '../../../api/Client';
import { environment } from '../../../environments/environment';
import { UmbracoTranslatePipe } from '../../core/pipes/umbraco-translate.pipe';
import { FormControl, FormsModule } from '@angular/forms';
import { DefaultValueOverrideService } from '../../services/default-value-override.service';
import { SkeletonModule } from 'primeng/skeleton';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    UmbracoTranslatePipe,
    FormsModule,
    SkeletonModule,
    NgOptimizedImage,
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  constructor(
    private _client: CommonContentService,
    public navigation: NavigationService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private router: Router,
    private _carouselService: DefaultValueOverrideService,
    cdref: ChangeDetectorRef
  ) {
    effect(() => {
      const currentPattern = this._carouselService.currentPattern();
      this.currentPattern.icon = currentPattern.icon
        ? this.MediaUrl + currentPattern.icon
        : '';
      this.currentPattern.headerTube = currentPattern.headerTube
        ? this.MediaUrl + currentPattern.headerTube
        : '';
      this.currentPattern.fontColor = currentPattern.fontColor
        ? currentPattern.fontColor
        : '';
      cdref.detectChanges();
    });
  }
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  headerSectionDto = {} as HeaderSectionDto;
  public CurrentLanguage: string | null = 'ar';
  public sreachTerm: string = '';
  public searchResultDto: SearchResultDto[] = [];

  public nav_img_hed_white: string = '/assets/images/nav_img_hed_white.svg';
  public nav_img_hed: string = '/assets/images/nav_img_hed.svg';
  public currentPattern = { headerTube: '', icon: '', fontColor: '' };

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.CurrentLanguage = localStorage.getItem('current-lang');
      this._client.headerWidget().subscribe((section) => {
        this.headerSectionDto = section;
      });

      // Listen for changes in the signal
    }
  }

  public loadSuggestion() {
    this._client.searchSuggestedContent().subscribe({
      next: (respone) => {
        this.searchResultDto = respone;
      },
    });
  }

  @ViewChild('navbarToggler', { static: false }) navbarToggler!: ElementRef;

  public startSearch() {

    if (this.navbarToggler) {
      this.navbarToggler.nativeElement.click();
    }

    let navigationExtras: NavigationExtras = {
      queryParams: { q: this.sreachTerm },
    };

    // Navigate to the login page with extras
    this.router.navigate(
      [this.CurrentLanguage === 'ar' ? '/ar/بحث' : '/en/search'],
      navigationExtras
    );
  }
  public swapLanguages() {
    // if (this.CurrentLanguage === 'en-US') {
    //   localStorage.setItem('current-lang', 'ar');
    //   location.href = this.urls!['/ar'] ?? '/ar';
    // }
    // if (this.CurrentLanguage === 'ar') {
    //   localStorage.setItem('current-lang', 'en-US');
    //   location.href = this.urls!['/en'] ?? '/en';
    // }
  }
}
