<div
  *ngIf="showNotFound"
  class="page-not-found"
  style="
    background: url(/assets/images/side_info_card.svg);
    background-repeat: no-repeat;
    background-position: left center;
  "
  [@fadeIn]
>
  <div class="main-container">
    <!-- Search Container for Text and Buttons -->
    <div class="search-container">
      <div>
        <h1>{{ "notFoundTitle" | umbracoTranslate | async }}</h1>
        <p>
          {{ "notFoundDesc" | umbracoTranslate | async }}
        </p>
      </div>

      <div class="search-box">
        <div class="input-container">
          <img
            class="search-icon"
            src="/assets/images/search_icon_green.svg"
            alt="404 Error"
          />
          <input
            type="text"
            [(ngModel)]="term"
            [ngModelOptions]="{ standalone: true }"
            placeholder="{{ 'search' | umbracoTranslate | async }}"
          />
          <!-- Unicode for search icon -->
        </div>
        <button
          [routerLink]="[CurrentLanguage === 'ar' ? '/ar/بحث' : '/en/search']"
          [queryParams]="{ q: term }"
        >
          {{ "search" | umbracoTranslate | async }}
        </button>
      </div>
      <button [routerLink]="['/']" class="return-home">
        {{ "backTohHomePage" | umbracoTranslate | async }}
      </button>
    </div>
    <!-- Error Circle with the 404 Image -->
    <div class="error-circle">
      <img src="/assets/images/404.svg" alt="404 Error" />
    </div>
  </div>
</div>

<ngx-spinner type="ball-scale-multiple" [color]="'#0C553C'"></ngx-spinner>
