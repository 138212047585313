<app-page-title-info
  *ngIf="IntroDto"
  [BackgroundImage]="IntroDto?.image"
  [Title]="IntroDto?.title ?? undefined"
  [Description]="IntroDto?.description ?? undefined"
></app-page-title-info>
<div class="gallery-filter-container" [@fadeIn]>
  <div class="filter-sec row">
    <div class="filter-input-sec col-md-12 col-lg-7">
      <span class="icon-search-input"></span>
      <input
        class="input filter"
        type="text"
        placeholder="{{ 'mediaSearch' | umbracoTranslate | async }}"
        #debounceInput
        [(ngModel)]="SearchTerm"
        (keyup.enter)="Search($event)"
      />
    </div>
    <select
      class="dropdown custom-select col-12 col-lg-2"
      (change)="onOptionChange($event)"
    >
      <option [value]="''" selected>
        {{ "mediaType" | umbracoTranslate | async }}
      </option>
      <option [value]="mediaTypes.MediaImage">
        {{ "pictures" | umbracoTranslate | async }}
      </option>
      <option [value]="mediaTypes.MediaVideo">
        {{ "video" | umbracoTranslate | async }}
      </option>
      <!-- <option *ngFor="let item of GalleryTypeDto; let i = index" [value]="item.id">
        {{ item.name }}
      </option> -->
    </select>
    <button (click)="Search($event)" class="search-btn col-12 col-lg-2">
      {{ "search" | umbracoTranslate | async }}
    </button>
  </div>
  <div class="gallery-container">
    @if (gallery && gallery?.length > 0) {
    <div class="row" appListAnimation listClassName="media-card">
      @for (item of gallery; track $index;let index = $index; let first = $first) {
      <div [id]="generateId($index)" [class]="first ? 'col-lg-8' : 'col-lg-4'">
        @if (item.type == mediaTypes.MediaVideo) {

        <iframe
          *ngIf="!divStates[generateId($index)]"
          [src]="item.safeurl"
          class="media-card"
          frameborder="0"
          sandbox="allow-scripts allow-same-origin allow-presentation"
          allow="autoplay; encrypted-media; fullscreen"
          loading="lazy"
        >
        </iframe>

        }

        <div
          *ngIf="divStates[generateId($index)]"
          class="media-card list-item-hide"
          style="background-size: cover; background-repeat: no-repeat"
          [style]="
            'background-image: url(' + MediaUrl + item.thumbnail.src + ')'
          "
        >
          <div class="media-card-caption">
            @if (item.type == mediaTypes.MediaVideo) {
            <div class="header">
              <span class="type">
                {{ "video" | umbracoTranslate | async }}
              </span>
              <span class="date label" *ngIf="!isDefaultDate(item.date)">
                <span class="icon" ></span>
                {{ item.date | date : "dd/MM/yyyy" }}
              </span>
            </div>
            } @else if (item.type == mediaTypes.MediaImage) {
            <div class="header">
              <span class="type">
                {{ "pictures" | umbracoTranslate | async }}
              </span>
              <span class="date label" *ngIf="!isDefaultDate(item.date)">
                <span class="icon" ></span>
                {{ item.date | date : "dd/MM/yyyy" }}
              </span>
              <span class="img-num label">
                <span class="icon"></span>
                {{ "number" | umbracoTranslate | async }}
                {{ "pictures" | umbracoTranslate | async }} (
                {{ item.images.length }} )
              </span>
            </div>
            }

            <h4 class="media-card-caption-text">
              {{ item.title }}
            </h4>
          </div>
          @if (item.type == mediaTypes.MediaVideo) {

          <div
            (click)="toggleDiv(generateId($index))"
            class="media-card-caption-hover"
          >
            <div class="hover-icon">
              <span class="icon"><span class="play-icon"></span></span>
              <span class="play-icon-text text"
                >{{ "play" | umbracoTranslate | async }}
                {{ "video" | umbracoTranslate | async }}</span
              >
            </div>
          </div>

          <iframe
            *ngIf="!divStates[generateId($index)]"
            src="https://www.youtube.com/embed/3uCp9VwOHOc?si=JZ1CuRgW-TFLenjI&autoplay=1"
            style="width: 100%; height: 100%"
            height="360"
            frameborder="0"
            sandbox="allow-scripts allow-same-origin allow-presentation"
            allow="autoplay; encrypted-media; fullscreen"
            loading="lazy"
          >
          </iframe>

          } @else if (item.type == mediaTypes.MediaImage) {
          <div class="media-card-caption-hover">
            <div
              class="hover-icon"
              data-bs-toggle="modal"
              [attr.data-bs-target]="'#exampleModal'+index"
            >
              <span class="preview-icon icon"></span>
              <span class="preview-icon-text text"
                >{{ "show" | umbracoTranslate | async }}
                {{ "pictures" | umbracoTranslate | async }}</span
              >
            </div>
          </div>
          }
        </div>
        @if (item.type == mediaTypes.MediaImage) {
        <!-- <p-galleria
          [value]="item.images"
          [(visible)]="item.display"
          [responsiveOptions]="responsiveOptions"
          [containerStyle]="{'max-width': '60%'}"
          [numVisible]="9"
          [circular]="true"
          [fullScreen]="true"
          [showItemNavigators]="true">
              <ng-template pTemplate="item" let-item>
                  <img [src]="item.itemImageSrc" style="width: 100%; display: block;"/>
              </ng-template>
              <ng-template pTemplate="thumbnail" let-item>
                  <div class="grid grid-nogutter justify-content-center">
                      <img [src]="item.thumbnailImageSrc" style="display: block;height: 74px;width: 74px;"/>
                  </div>
              </ng-template>
      </p-galleria> -->
        <div
          class="modal fade modal-lg"
          [attr.id]="'exampleModal'+index"
          tabindex="-1"
          [attr.aria-labelledby]="'exampleModal'+index+'Label'"
          aria-hidden="true"
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <div
                  [id]="'carouselGallery'+index"
                  class="carousel slide"
                  data-bs-ride="true"
                >
                  <div class="carousel-indicators">
                    @for (gallery of item.images; track $index; let first =
                    $first) {
                    <button
                      type="button"
                      [attr.data-bs-target]="'#carouselGallery'+index"
                      [attr.data-bs-slide-to]="$index"
                      [class]="first ? 'active' : ''"
                      aria-current="true"
                      [attr.aria-label]="'Slide ' + $index + 1"
                    >
                      <img
                        [ngSrc]="gallery.itemImageSrc"
                        width="70"
                        height="70"
                        [alt]="gallery.alt"
                      />
                    </button>
                    }
                  </div>
                  <div class="carousel-inner">
                    @for (gallery of item.images; track $index; let first =
                    $first) {
                    <div class="carousel-item" [class]="first ? 'active' : ''">
                      <img
                        [ngSrc]="gallery.itemImageSrc"
                        class="d-block w-100 h-100"
                        width="650"
                        height="550"
                        [alt]="item.alt"
                      />
                    </div>
                    }
                  </div>
                  <button
                    class="carousel-control-prev"
                    type="button"
                    [attr.data-bs-target]="'#carouselGallery'+index"
                    data-bs-slide="prev"
                  >
                    <span
                      class="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Previous</span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    [attr.data-bs-target]="'#carouselGallery'+index"
                    data-bs-slide="next"
                  >
                    <span
                      class="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span class="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        }
      </div>
      }
    </div>
    <div class="pagination-sec">
      <div class="pagination-sec">
        <button
          (click)="LoadMore($event)"
          *ngIf="CurrentCount < TotalCount"
          class="btn more"
        >
          {{ "moreNews" | umbracoTranslate | async }}
        </button>
      </div>
    </div>
    } @if (!Loading && gallery.length === 0){
    <div style="text-align: center">
      {{ "sorry,noResults" | umbracoTranslate | async }}
    </div>

    }
  </div>
</div>
<app-page-rating></app-page-rating>
