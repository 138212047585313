<div [@fadeIn]>
  @if (IntroDto) {
  <app-page-title-info
    [Title]="IntroDto?.title ?? undefined"
    [Description]="IntroDto?.description ?? undefined"
  ></app-page-title-info
  >}
  <div class="image-container">
    @if (supplierDetailsDto) {
    <div class="image">
      <img
        [ngSrc]="MediaUrl + supplierDetailsDto.image?.src"
        width="490"
        height="946"
        [alt]="supplierDetailsDto.image?.name"
      />
    </div>

    <div class="content">
      <div class="supplier-service-sec">
        <div
          class="details"
          [innerHtml]="supplierDetailsDto.details | sanitizedHtml"
        ></div>
        <div class="row">
          <button class="btn register-btn col-lg-5 col-sm-5 mb-2">
            <a
              [href]="supplierDetailsDto.registration?.url"
              [target]="supplierDetailsDto.registration?.target"
              >{{ supplierDetailsDto.registration?.name }}</a
            >
          </button>
          <button class="btn register-btn active-comp col-sm-3 col-lg-3 me-2">
            <a
              [href]="supplierDetailsDto.login?.url"
              [target]="supplierDetailsDto.login?.target"
              >{{ supplierDetailsDto.login?.name }}</a
            >
          </button>
          <button
            class="btn register-btn active-comp col-lg-3 col-sm-3 underline no-background"
            style="background: transparent !important"
          >
            <a
              [href]="supplierDetailsDto.activeTenders?.url"
              [target]="supplierDetailsDto.activeTenders?.target"
              >{{ supplierDetailsDto.activeTenders?.name }}</a
            >
          </button>
        </div>
      </div>
    </div>

    <div class="secondary-content d-flex">
      <div>
        <div class="supplier-service-sec">
          <div
            class="registeration-steps"
            [innerHtml]="supplierDetailsDto.registrationSteps | sanitizedHtml"
          ></div>
        </div>

        <div class="links-container px-4">
          @for (item of supplierDetailsDto.attachments; track $index) {
          <div class="mb-3">
            <img class="mx-2" src="../../../assets/images/import.png" />
            <a target="_blank" [href]="MediaUrl + item.src">{{ item.name }}</a>
          </div>
          }
        </div>
        <div
          class="registeration-steps"
          [innerHtml]="supplierDetailsDto.extraHelp | sanitizedHtml"
        ></div>
      </div>
      <div>
        <img [src]="MediaUrl+ supplierDetailsDto.pattern?.src" />
      </div>
    </div>
    }
  </div>
</div>
