import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import {
  CommonContentService,
  IntroDto,
  LogosDownloadDto,
  VisualIdentityPageContentService,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { forkJoin } from 'rxjs';
import { environment } from '../../../environments/environment';
import { PageTitleInfoComponent } from '../../components/shared/page-title-info/page-title-info.component';
import { UmbracoTranslatePipe } from '../../core/pipes/umbraco-translate.pipe';
import { CommonModule } from '@angular/common';
import { PageRatingComponent } from '../../components/shared/page-rating/page-rating.component';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-identity-vision',
  standalone: true,
  imports: [
    CommonModule,
    UmbracoTranslatePipe,
    PageTitleInfoComponent,
    PageRatingComponent,
  ],
  templateUrl: './identity-vision.component.html',
  styleUrl: './identity-vision.component.scss',
  animations: [fadeInAnimation],
})
export class IdentityVisionComponent implements OnInit, OnDestroy {
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public LogoDownloadDto!: LogosDownloadDto;
  public IntroDto!: IntroDto;
  constructor(
    private _client: VisualIdentityPageContentService,
    private _contentPagesServices: ContentPagesServices,
    private _common: CommonContentService,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}
  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    forkJoin({
      introDto: this._common.introText(currentPagePath),
      downloadLogoDto: this._client.downloadableLogos(currentPagePath),
    }).subscribe(async ({ introDto, downloadLogoDto }) => {
      this.IntroDto = introDto;
      this.LogoDownloadDto = downloadLogoDto;
      this._cotnentLoadingService.setLoadingState(true);
    });
  }
  downloadFile(fileUrl: string, fileName: any) {
    // Fetch the file using fetch API or XMLHttpRequest (depending on your need)
    fetch(fileUrl)
      .then((response) => response.blob()) // Convert the response to a Blob (binary data)
      .then((blob) => {
        // Create an object URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary link element
        const link = document.createElement('a');
        link.href = url; // Set the href to the Blob URL
        link.download = fileName; // Set the filename for download

        // Programmatically trigger the download
        link.click();

        // Release the Blob URL to free memory
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading the file', error);
      });
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
