import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { ListAnimationDirective } from '../../core/pipes/list-animation.directive';
import { PageRatingComponent } from '../../components/shared/page-rating/page-rating.component';
import {
  CommonContentService,
  ExtraHelpDto,
  FAQPageContentService,
  FaqsItemsDto,
  FrequentlyAskedQuestionsItemDto,
  IntroDto,
  LookupDto,
  LookupsContentService,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { TranslationService } from '../../services/translation.service';
import { UmbracoTranslatePipe } from '../../core/pipes/umbraco-translate.pipe';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { environment } from '../../../environments/environment';
import { SanitizedHtmlPipe } from '../../core/pipes/sanitized-html.pipe';
import { debounceTime, fromEvent, map } from 'rxjs';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';
import { PageTitleInfoComponent } from "../../components/shared/page-title-info/page-title-info.component";

@Component({
  selector: 'app-frequent-questions',
  templateUrl: './frequent-questions.component.html',
  styleUrl: './frequent-questions.component.scss',
  standalone: true,
  imports: [
    ListAnimationDirective,
    PageRatingComponent,
    PageRatingComponent,
    NgxSpinnerModule,
    UmbracoTranslatePipe,
    CommonModule,
    FormsModule,
    RouterModule,
    SanitizedHtmlPipe,
    PageTitleInfoComponent
],
  animations: [fadeInAnimation],
})
export class FrequentQuestionsComponent implements OnInit, OnDestroy {
  public IntroDto!: IntroDto;
  constructor(
    private _common: CommonContentService,
    private _client: FAQPageContentService,
    private _lookup: LookupsContentService,
    private _contentPage: ContentPagesServices,
    private _spinner: NgxSpinnerService,
    private _translationService: TranslationService,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}

  public Lookups: LookupDto[] = [];
  public ExtraHelpDto: ExtraHelpDto | undefined = undefined;
  public term: string = '';
  public categoryId: string = '';
  public frequentlyAskedQuestionsItemDto: FrequentlyAskedQuestionsItemDto[] =
    [];
  public FaqsItemsDto: FaqsItemsDto | undefined = undefined;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  @ViewChild('debounceInput', { static: true }) debounceInput!: ElementRef;
  ngOnInit(): void {
    this._spinner.show();
    var currentPageUrl = this._contentPage.getPathForCurrentPage();
    this._common.introText(currentPageUrl).subscribe((res) => {
      this.IntroDto = res;
      this._cotnentLoadingService.setLoadingState(true);
    });
    this._client.frequentlyAskedQuestionsExtraHelp(currentPageUrl).subscribe({
      next: (res) => {
        this.ExtraHelpDto = res;
      },
    });

    this._client.frequentlyAskedQuestionsData(currentPageUrl).subscribe({
      next: (res) => {
        this.FaqsItemsDto = res;
        this._cotnentLoadingService.setLoadingState(true);
      },
    });

    this._lookup.lookups('faqCategoryItem').subscribe({
      next: (res) => {
        this.Lookups = res;
      },
      complete: () => {
        this.search('', '');
      },
    });

    fromEvent(this.debounceInput.nativeElement, 'input')
      .pipe(
        debounceTime(500), // half a sec
        map((event: any) => event.target.value)
      )
      .subscribe((value: string) => {
        this.search(value, this.categoryId);
      });
  }

  public search(term: string, categoryId: string) {
    this.categoryId = categoryId;
    this._spinner.show();
    this._client
      .frequentlyAskedQuestionsItems(this.term, categoryId, 0, 1000)
      .subscribe({
        next: (res) => {
          this.frequentlyAskedQuestionsItemDto = res.items!;
        },
        complete: () => {
          this._spinner.hide();
          window.scrollBy({ top: +50, behavior: 'smooth' });
        },
      });
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
