<app-page-title-info
*ngIf="IntroDto"
[BackgroundImage]="IntroDto?.image"
[Title]="IntroDto?.title ?? undefined"
[Description]="IntroDto?.description ?? undefined"
></app-page-title-info><div class="frequent-questions-container" [@fadeIn]>
  <div class="department-type-sec" *ngIf="FaqsItemsDto?.filterEnabled">
    <span class="label"> {{
        "chooseSector" | umbracoTranslate | async
      }}  </span>
    <div  class="types-sec">
      <input
        type="radio"
        class="btn-check"
        name="options-outlined"
        id="default-question"
        autocomplete="off"
        checked
        (click)="search(term, '')"
      />
      <label class="btn btn-outline-success" for="default-question">{{
        "all" | umbracoTranslate | async
      }}</label>

      @for (Lookup of Lookups; track $index) {

      <input
        type="radio"
        class="btn-check"
        name="options-outlined"
        [id]="'question' + $index"
        autocomplete="off"
        (click)="search(term, Lookup.id?.toString() ?? '')"
      />
      <label class="btn btn-outline-success" [for]="'question' + $index">
        {{ Lookup.name }}</label
      >

      }
    </div>
  </div>
  <div class="row">
    <div
      class="d-flex flex-column col-xl-7 col-lg-9 col-md-11 p-0 m-auto gap-60"
    >
      <div class="search-list-sec">
        <div class="search-sec">
          <div class="filter-input-sec">
            <span class="icon-search-input"></span>
            <input
              [(ngModel)]="term"
              class="input filter"
              type="text"
              placeholder="{{ 'search' | umbracoTranslate | async }}"
              #debounceInput
            />
          </div>
          <button class="search-button" (click)="search(term, categoryId)">
            {{ "search" | umbracoTranslate | async }}
          </button>
        </div>
        <div class="frequent-questions-sec">
          <h5 class="title">{{ FaqsItemsDto?.title }}</h5>
          <span class="sub-title">
            {{ FaqsItemsDto?.description }}
          </span>
          <div
            class="accordion accordion-flush"
            id="questions-list"
            appListAnimation
            listClassName="accordion-item"
          >
            @for (frequentlyAskedQuestionsItem of
            frequentlyAskedQuestionsItemDto; track $index) {

            <div
              class="accordion-item w-100 d-flex flex-column gap-2 list-item-hide"
            >
              <h2 class="accordion-header">
                <button
                  class="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  [attr.data-bs-target]="'#flush-collapse' + $index"
                  aria-expanded="false"
                  [attr.aria-controls]="'flush-collapse' + $index"
                >
                  {{ frequentlyAskedQuestionsItem.question }}
                  <span class="collapse-icon"></span>
                </button>
              </h2>
              <div
                [id]="'flush-collapse' + $index"
                class="accordion-collapse collapse"
                data-bs-parent="#accordionFlushExample"
              >
                <div
                  class="accordion-body"
                  [innerHtml]="
                    frequentlyAskedQuestionsItem.answer | sanitizedHtml
                  "
                ></div>
              </div>
            </div>
            }

            @empty {
              <div style="align-content: center;">  {{ "sorry,noResults" | umbracoTranslate | async }}</div>
            }
          </div>
        </div>
      </div>

      <div *ngIf="ExtraHelpDto?.enabled" class="more-help-sec">
        <span class="title">{{ ExtraHelpDto?.title }}</span>
        <span class="sub-title">{{ ExtraHelpDto?.description }}</span>
        <hr class="w-85 mx-auto" />
        <div class="contacts-info row w-100">
          @for (channel of ExtraHelpDto?.channels; track $index) {
          <div class="col-lg-6">
            <div class="contact-card">
              <div class="contact-info">
                <span class="label">{{ channel.title }}</span>
                <div class="info">
                  <span
                    class="info-icon"
                    [style]="
                      'background-image: url( \' ' +
                      (MediaUrl + channel.icon?.src) +
                      ' \' );'
                    "
                  ></span>
                  {{ channel.value }}
                </div>
              </div>
              <div class="response-sec">
                <span class="label"> {{ channel.description }} </span>
              </div>
            </div>
          </div>
          }
        </div>
        <button
          [routerLink]="ExtraHelpDto?.clickToAction?.url"
          [target]="ExtraHelpDto?.clickToAction?.target"
          class="btn contact-us"
        >
          {{ ExtraHelpDto?.clickToAction?.name }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-page-rating></app-page-rating>
<ngx-spinner type="ball-scale-multiple" [color]="'#0C553C'"></ngx-spinner>
