<header
  class="header-con"
  [ngStyle]="{ position: navigation.isHomePage() ? 'absolute' : 'static' }"
>
  @if (headerSectionDto) {
  <div class="img-sec">
    @if (currentPattern.headerTube !== '') {
    <img
      [ngSrc]="currentPattern.headerTube"
      alt=""
      width="135" height="48"
    />
  }
  @if (currentPattern.headerTube === '') {
    <img
      [ngSrc]="
        navigation.isHomePage()
          ? '/assets/images/nav_img_hed_white.svg'
          : '/assets/images/nav_img_hed.svg'
      "
      alt=""
      width="135" height="48"
    />
  }
  </div>
  <div
    class="container-fluid"
    [class]="navigation.isHomePage() ? 'nav-bar-bg-green' : 'nav-bar-bg-white'"
  >
    <nav class="navbar navbar-expand-lg">
      <a class="navbar-brand" href="#">
        @if (currentPattern.icon !== '') {
        <img
          [ngSrc]="
            currentPattern.icon
          "
          alt=""
          width="135" height="48"
        />
        }
        @if (currentPattern.icon === '') {
        <img
          [ngSrc]="
            headerSectionDto.defaultLogo
              ? headerSectionDto.defaultLogo
              : navigation.isHomePage()
              ? '/assets/images/nav_icon_hed_white.svg'
              : '/assets/images/nav_icon_hed.svg'
          "
          alt=""
          width="135" height="48"
        />
        }
      </a>
      <button
        #navbarToggler
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
        aria-controls="navbarScroll"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse space-between" id="navbarScroll">
        <ul class="navbar-nav my-2 my-lg-0 navbar-nav-scroll">
          @if (headerSectionDto?.mainNavigation?.length ??0 > 0) {
          <li class="nav-item">
            <button [ngStyle]="currentPattern.fontColor ? { 'color': currentPattern.fontColor } : {}"
              class="nav-link active"
              aria-current="page"
              [routerLink]="headerSectionDto?.mainNavigation?.[0]?.url"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
            >
            <bdi>{{headerSectionDto?.mainNavigation?.[0]?.name}}</bdi>
            </button>
          </li>
          @for (linkItem of headerSectionDto.mainNavigation; track $index) { @if
          ($index > 0) { @if (linkItem.enableSubNavigation === true) {
          <li class="nav-item dropdown">
            <a [ngStyle]="currentPattern.fontColor ? { 'color': currentPattern.fontColor } : {}"
              class="nav-link dropdown-toggle"
              [routerLink]="linkItem.url"
              [target]="linkItem.target ? linkItem.target : ''"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
            <bdi>{{ linkItem.name }}</bdi>
            </a>
            <ul class="dropdown-menu">
              @for (subLinkItem of linkItem.subNavigation; track $index;) {
              <li>
                <button
                  class="dropdown-item"
                  [target]="subLinkItem.target ? subLinkItem.target : ''"
                  [routerLink]="subLinkItem.url"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarScroll"
                >
                  {{ subLinkItem.name }}
                </button>
              </li>
              @if ($index < (linkItem?.subNavigation?.length ?? 0) - 1) {
              <li><hr class="dropdown-divider" /></li>
              } }
            </ul>
          </li>
          }@else {
          <li class="nav-item">
            <button
              class="nav-link" [ngStyle]="currentPattern.fontColor ? { 'color': currentPattern.fontColor } : {}"
              [ngClass]="{ active: $index === 0 }"
              [routerLink]="linkItem.url"
              [target]="linkItem.target ? linkItem.target : ''"
              data-bs-toggle="collapse"
              data-bs-target="#navbarScroll"
            >
            <bdi>{{ linkItem.name }}</bdi>
            </button>
          </li>
          } } }}
        </ul>

        <form class="search-container" role="search">
          <!-- <button class="lang-btn">
            <span class="lang-icon"></span>
            En
          </button> -->
          <!-- <button class="circle-button">
                            <span class="search-icon"></span>
                          </button> -->
          <div class="dropdown dropdown-circle-button-container">
            <button
              id="topNavSearchButton"
              type="button"
              class="circle-button dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-expanded="false"
              data-bs-auto-close="outside"
              (click)="loadSuggestion()"
            >
              <span class="search-icon"></span>
            </button>

            <div class="dropdown-menu" id="dropdown">
              <div class="dropdown-container">
                <span class="search-title">{{
                  "whatAreYouLookingFor" | umbracoTranslate | async
                }}</span>
                <div class="input-container">
                  <span class="icon"></span>
                  <input
                    type="text"
                    (keyup)="$event.keyCode === 13 && startSearch()"
                    [(ngModel)]="sreachTerm"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="{{ 'search' | umbracoTranslate | async }}"
                    class="input-field"
                  />
                </div>
                <span class="recommendation-title">
                  {{ "suggestion" | umbracoTranslate | async }}
                </span>

                <div
                  class="suggestion-list"
                  aria-labelledby="dropdownMenuButton1"
                >
                  @for (item of searchResultDto; track item.key) {
                  <div
                    class="suggestion-item"
                    [routerLink]="item.url"
                    aria-labelledby="topNavSearchButton"
                  data-bs-toggle="collapse" data-bs-target="#dropdown" aria-controls="dropdown"
                  >
                    <span class="suggestion-text">
                      {{ item.title }}
                    </span>
                    <span class="badge badge-pill badge-light suggestion-type">
                      {{ item.type! | umbracoTranslate | async }}
                    </span>
                  </div>
                  }
                </div>
                <button
                  [routerLink]="[
                    CurrentLanguage === 'ar' ? '/ar/بحث' : '/en/search'
                  ]"
                  [queryParams]="{ q: sreachTerm }"
                  class="search-button"
                  data-bs-toggle="dropdown"
                  type="button"
                  data-bs-toggle="collapse" data-bs-target="#dropdown" aria-controls="dropdown"
                >
                  {{ "search" | umbracoTranslate | async }}
                </button>
              </div>
            </div>
          </div>
          <!-- <span class="tanmya-icon bg-white"></span> -->
          <a [href]="headerSectionDto.extraButtonNavigationOne?.url" [target]="headerSectionDto.extraButtonNavigationOne?.target" class="tanmya-icon bg-green"></a>

          <div class="input-container">
            <span class="icon"></span>
            <input
                    type="text"
                    (keyup)="$event.keyCode === 13 && startSearch()"
                    [(ngModel)]="sreachTerm"
                    [ngModelOptions]="{ standalone: true }"
                    placeholder="{{ 'search' | umbracoTranslate | async }}"
                    class="input-field"
            />
          </div>
        </form>
      </div>
    </nav>
  </div>
  }
</header>
