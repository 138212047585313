<div  [@fadeIn]>
@if (IntroDto) {
  <app-page-title-info
    [BackgroundImage]="IntroDto?.image"
    [Title]="IntroDto?.title ?? undefined"
    [Description]="IntroDto?.description ?? undefined"
  >
  </app-page-title-info>
  }
@if (JoinUsContentDto) {

  <div class="image-container">
    <div class="image">
      <img [ngSrc]="MediaUrl+JoinUsContentDto.image?.src" width="946" height="490" [alt]="JoinUsContentDto.image?.name" />
    </div>
    <div class="content">
      <h3 class="title">{{JoinUsContentDto.title}}</h3>
      <span class="sub-title">{{JoinUsContentDto.description}} </span>

      <div class="opportunities-list" appListAnimation listClassName="opportunity-card">
        @for (item of JoinUsContentDto.items; track $index) {
        <div class="opportunity-card">
            <span class="icon-card" [ngStyle]="{'background-image':'url('+ MediaUrl +item.icon?.src+')'}"></span>
            <span class="label">{{item.title}}</span>
            <p class="text">{{item.description}}</p>
        </div>
      }
      </div>
      <p class="info">{{JoinUsContentDto.details}}</p>
      @if (JoinUsContentDto.clickToAction) {
        <a [href]="JoinUsContentDto.clickToAction.url" [target]="JoinUsContentDto.clickToAction.target" class="text-decoration-none">
          <button class="btn show-opportunities">{{JoinUsContentDto.clickToAction.name}}</button>
        </a>
      }
    </div>
  </div>
}
<app-page-rating></app-page-rating>

</div>
