<div class="d-flex flex-column m-0" [@fadeIn]>
@if (IntroDto) {
<app-page-title-info
  [BackgroundImage]="IntroDto?.image"
  [Title]="IntroDto?.title ?? undefined"
  [Description]="IntroDto?.description ?? undefined"
>
</app-page-title-info>
}
@if (LogoDownloadDto?.items && LogoDownloadDto.items!.length > 0) {
<div class="table-responsive">
  <div class="rounded">
    <table class="table custom-table">
        <thead class="table-light text-center ">
            <tr>
                <th class="header" scope="col">{{ 'logoExtension' | umbracoTranslate | async }}</th>
                <th class="header" scope="col">{{ 'link' | umbracoTranslate | async }}</th>
              </tr>
          </thead>
          <tbody class="text-center">
            @for (item of LogoDownloadDto.items; track $index) {
            <tr>
                <td class="label">{{item.title}}</td>
                <td class="link"><a (click)="downloadFile(MediaUrl+item.logo?.src,item.logo!.name)">{{ 'downloadHere' | umbracoTranslate | async }}</a></td>
              </tr>
            }
          </tbody>
    </table>
  </div>
  </div>
}
</div>

<app-page-rating></app-page-rating>
