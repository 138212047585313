<footer *ngIf="showFooter">
  @if (footerSectionDto) {
  <div class="footer-info">
    <div class="contact-info">
      <img
        *ngIf="footerSectionDto?.logo?.src != null"
        [ngSrc]="MediaUrl + (footerSectionDto.logo?.src ?? '')"
        [alt]="footerSectionDto.logo?.name"
        width="147" height="48"
        class="logo"
      />
      <span class="info">
        <bdi>{{ footerSectionDto.logoBrief }}</bdi>
      </span>
      <div class="horizontal-border"></div>
      <span class="contacts">
        <span class="label">
          {{ "email" | umbracoTranslate | async }}
        </span>
        <span>{{ footerSectionDto.supportEmail }}</span>
      </span>
      <div class="horizontal-border"></div>
      <span class="contacts">
        <span class="label"> {{ "uniNumber" | umbracoTranslate | async }}</span>
        <span>{{ footerSectionDto.supportPhoneNumber }}</span>
      </span>
      <div class="horizontal-border"></div>
      <span class="social-links">
        @if (socialSectionDto) {
        <span class="label">{{ socialSectionDto.brief }}</span>
        @if (socialSectionDto?.socialLinks?.length ??0 > 0) { @for (logo of
        socialSectionDto.socialLinks; track $index) {
        <span class="social-logo">
          <a [href]="logo.url" target="_blank"
            ><img [ngSrc]="MediaUrl + logo.icon?.src" width="16" height="16" [alt]="logo.name"
          /></a>
        </span>
        } } }
      </span>
    </div>
    <div class="navigation-links">
      <div class="nav-sec">
        @if (footerSectionDto?.mainNavigation?.length ??0 > 0) { @for (section
        of footerSectionDto.mainNavigation; track $index) {
        <div class="sec">
          <ul class="items-list">
            <li class="list-haed"><bdi>{{ section.name }}</bdi></li>
            @if (section?.subNavigation?.length ??0 > 0) { @for (linkItem of
            section.subNavigation; track $index) {
            <li>
              <a *ngIf="linkItem.type==0"
                class="list-item"
                [routerLink]="linkItem.url"
                [target]="linkItem.target!"
                ><bdi>{{ linkItem.name }}</bdi></a
              >
                <a *ngIf="linkItem.type==2"
                class="list-item"
                [href]="linkItem.url"
                [target]="linkItem.target!"
                ><bdi>{{ linkItem.name }}</bdi></a
              >
            </li>
            } }
          </ul>
        </div>
        }
        <div class="access-tools">
          <ul class="items-list">
            <li class="list-head">
              {{ "Accessibility " | umbracoTranslate | async }}
            </li>
            <li class="list-item text-decoration-none">
                {{ "fontSize " | umbracoTranslate | async }} <span class="minus-font" (click)="decreaseFontSize()"></span> A <small>A</small> <span class="plus-font" (click)="increaseFontSize()"></span>
            </li>
            <!-- <li>
              <a class="list-item" href="#"
                >{{ "changeColors " | umbracoTranslate | async }}
              </a>
            </li> -->
            <li>
              <button *ngIf="footerSectionDto.enableEnglishLanguage" (click)="swapLanguages()" class="lang-btn">
                <span class="lang-icon"></span>
                @if(CurrentLanguage === "en-US") { AR } @if(CurrentLanguage ===
                "ar") { EN }
              </button>
            </li>
          </ul>
        </div>
        }
      </div>
      <div class="side-icon"></div>
    </div>
    <div class="scroll-to-top" (click)="scrollToTop()">
      <div class="line"></div>
      <div class="circle">
        <i class="arrow-up"></i>
      </div>
    </div>
    <div class="copy-rights">
      <span class="rights">{{ footerSectionDto.copyRightText }}</span>
      <div class="policies">
        @if (footerSectionDto?.smallBottomNavigation?.length ??0 > 0) { @for
        (item of footerSectionDto.smallBottomNavigation; track $index) {
        <a
          [routerLink]="item.url"
          [target]="item.target!"
          class="labels d-flex gap-2"
          >@if($index === 0){<span class="map-icon"></span>}<bdi>{{ item.name }}</bdi></a
        >
        }}
      </div>
    </div>
  </div>
  }
</footer>
