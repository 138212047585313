import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';
import { NewsItemDto, NewsItemDto2 } from '../../../../api/Client';
import { RouterModule } from '@angular/router';
import { environment } from '../../../../environments/environment';
import { UmbracoTranslatePipe } from '../../../core/pipes/umbraco-translate.pipe';

@Component({
  selector: 'app-another-news',
  standalone: true,
  imports: [CommonModule, ListAnimationDirective, UmbracoTranslatePipe,RouterModule,NgOptimizedImage],
  templateUrl: './another-news.component.html',
  styleUrl: './another-news.component.scss',
})
export class AnotherNewsComponent {
  @Input() newsItemDto!: NewsItemDto2[];
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
}
