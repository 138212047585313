import { Component, OnDestroy, OnInit } from '@angular/core';
import { AnotherNewsComponent } from '../../components/tanmiah-news/another-news/another-news.component';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { NewsDetailsComponent } from '../../components/tanmiah-news/news-details/news-details.component';
import {
  CommonContentService,
  FeaturedNewsDto,
  IntroDto,
  MetaDataDto,
  NewsDetailsDto,
  NewsItemPageContentService,
  NewsPageContentService,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { environment } from '../../../environments/environment';
import { forkJoin } from 'rxjs';
import { PageTitleInfoComponent } from '../../components/shared/page-title-info/page-title-info.component';
import { CommonModule } from '@angular/common';
import { PageRatingComponent } from '../../components/shared/page-rating/page-rating.component';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-tanmiah-news-details',
  standalone: true,
  imports: [
    NewsDetailsComponent,
    PageTitleInfoComponent,
    CommonModule,
    PageRatingComponent,
  ],
  templateUrl: './tanmiah-news-details.component.html',
  styleUrl: './tanmiah-news-details.component.scss',
  animations: [fadeInAnimation],
})
export class TanmiahNewsDetailsComponent implements OnInit, OnDestroy {
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public IntroDto!: IntroDto;
  NewsContentDto!: NewsDetailsDto;
  NewsMetaDataContentDto!: MetaDataDto;
  RelatedNewsDto!: FeaturedNewsDto[];
  constructor(
    private _client: NewsItemPageContentService,
    private _common: CommonContentService,
    private _contentPagesServices: ContentPagesServices,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}
  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    forkJoin({
      intoDto: this._common.introText(currentPagePath),
      newsContentDto: this._client.newsContent(currentPagePath),
      newsMetaDataContentDto: this._common.sEOInfo(currentPagePath),
      relatedNewsDto: this._client.relatedNews(currentPagePath),
    }).subscribe(
      async ({
        intoDto,
        newsContentDto,
        newsMetaDataContentDto,
        relatedNewsDto,
      }) => {
        this.IntroDto = intoDto;

        var xx = newsContentDto.details?.replaceAll(
          'src="/umbraco-media/',
          'src="' + this.MediaUrl + '/umbraco-media/'
        );
        newsContentDto.details = xx;
        (this.NewsContentDto = newsContentDto),
          (this.NewsMetaDataContentDto = newsMetaDataContentDto),
          (this.RelatedNewsDto = relatedNewsDto);

        this._cotnentLoadingService.setLoadingState(true);
      }
    );
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
