<div [@fadeIn]>
  @if (IntroDto) {
  <app-page-title-info
    [BackgroundImage]="IntroDto.image"
    [Title]="IntroDto.title ?? ''"
    [Description]="IntroDto.description ?? ''"
  ></app-page-title-info>
  }
  <app-news-filter></app-news-filter>
  <app-page-rating></app-page-rating>
</div>
