<div  [@fadeIn]>
@if (IntroDto) {
    <app-page-title-info
    [BackgroundImage]="IntroDto.image"
    [Title]="IntroDto.title ?? ''"
    [Description]="IntroDto.description ??''"
    ></app-page-title-info>
    }
<app-news-details [newsContentDto]="NewsContentDto" [newsMetaDataContentDto]="NewsMetaDataContentDto" [relatedNewsDto]="RelatedNewsDto"></app-news-details>
<app-page-rating></app-page-rating>
</div>
