import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AnotherNewsComponent } from "../another-news/another-news.component";
import { ListAnimationDirective } from '../../../core/pipes/list-animation.directive';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import { UmbracoTranslatePipe } from '../../../core/pipes/umbraco-translate.pipe';
import { RouterModule } from '@angular/router';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../../environments/environment';
import { FeaturedNewsDto, LookupDto, LookupsContentService, NewsItemDto, NewsItemDto2, NewsItemPageContentService, NewsPageContentService } from '../../../../api/Client';
import { TranslationService } from '../../../services/translation.service';
import { debounceTime, forkJoin, fromEvent, map } from 'rxjs';
import { ContentPagesServices } from '../../../services/content-page-services.service';

@Component({
  selector: 'app-news-filter',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    UmbracoTranslatePipe,
    NgxSpinnerComponent,ListAnimationDirective,AnotherNewsComponent,NgOptimizedImage],
  templateUrl: './news-filter.component.html',
  styleUrl: './news-filter.component.scss'
})
export class NewsFilterComponent implements OnInit {
  @ViewChild('debounceInput', { static: true }) debounceInput!: ElementRef;

  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public NewsItemDto: NewsItemDto2[] = [];
  public NewsTypeDto: LookupDto[] = [];
  public TotalCount: number = 0;
  public CurrentCount: number = 0;
  public SearchTerm: string = '';
  public newsTypeId: string | null = null;
  public Loading: boolean = false;
  FeatureNewsDto!: FeaturedNewsDto[];
  selectedNews!: FeaturedNewsDto;

  constructor(
    private _client: NewsPageContentService,
    private _lokup: LookupsContentService,
    public _translationService: TranslationService,
    private _contentPagesServices: ContentPagesServices,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    this.spinner.show();
    this.Loading = true;
    this._lokup.lookups('newsTypeItem').subscribe({
      next: async (response) => {
        this.NewsTypeDto = response;
      },
      error: () => {
        this.spinner.hide();
      },
    });

    this._client.featuredNews(currentPagePath).subscribe(  featureNewsDto  => {
      this.FeatureNewsDto = featureNewsDto;
      if (this.FeatureNewsDto.length > 0) {
      this.selectedNews = this.FeatureNewsDto[0]
      }
    })

    this.SearchNews(this.SearchTerm!, this.newsTypeId!),

    fromEvent(this.debounceInput.nativeElement, 'input')
      .pipe(
        debounceTime(500), // half a sec
        map((event: any) => event.target.value)
      )
      .subscribe((value: string) => {
        this.Search({});
      });
  }

  onOptionChange(event: any) {
    this.CurrentCount = 0;
    this.NewsItemDto = [];
    this.SearchNews(this.SearchTerm!, this.newsTypeId!);
  }
  Search(event: any) {
    this.CurrentCount = 0;
    this.NewsItemDto = [];
    this.SearchNews(this.SearchTerm!, this.newsTypeId!);
  }

  LoadMore(event: any) {
    this.SearchNews(this.SearchTerm!, this.newsTypeId!);
  }

  //projects(searchTerm?: string | undefined, regionId?: string | undefined, skipCount?: number | undefined, maxResultCount?: number | undefined)
  private SearchNews(searchTerm: string, typeId: string | undefined) {
    this.Loading = true;
    this.spinner.show();
    this._client
      .searchNewsItems(
        searchTerm!,
        typeId === 'null' ? '' : (typeId ?? ''),
        this.CurrentCount!,
        6
      )
      .subscribe({
        next: async (result) => {
          this.NewsItemDto.push(...result.items!);
          this.TotalCount = result.totalCount!;
          this.CurrentCount += result.items!.length;
          setTimeout(() => {
          //  this.showPartners();
          }, 500);
        },
        complete: () => {
          this.Loading = false;
          this.spinner.hide();
          window.scrollBy({ top: +150, behavior: 'smooth' });
        },
        error: () => {
          this.spinner.hide();
        },
      });
  }

}

