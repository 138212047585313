import {
  ActivatedRouteSnapshot,
  ResolveFn,
  RouterStateSnapshot,
  Routes,
} from '@angular/router';
import { NotFoundComponent } from './umbraco/not-found/not-found.component';
import { inject } from '@angular/core';
import { AppInitService } from './AppInitService';

export const routesResolver: ResolveFn<any> = async (
  route: ActivatedRouteSnapshot,
  state: RouterStateSnapshot,
) => {
  return await inject(AppInitService).init();
};

export const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
    import('./umbraco/umbraco.module').then((m) => m.UmbracoModule),
   // resolve: { customRoutes: routesResolver },
  },
  {
    path: '**',
    component: NotFoundComponent,
    resolve: { customRoutes: routesResolver },
  }
];
