<div class="news-details-container">
  <div class="news-details-sec">
    @if (newsContentDto) {
    <div class="header-sec">
      <div class="info-sec">
        <!-- <span class="news-category">{{ newsContentDto.category?.name }}</span> -->
        <span class="news-date">
          <span class="date-icon-green"></span>
          {{ newsContentDto.date | date : "dd/MM/yyyy" }}
        </span>
      </div>
      <h3 class="news-title">{{ newsContentDto.title }}</h3>
      <!-- <button class="share-news" data-bs-toggle="modal" data-bs-target="#share">
        <span class="share-icon-w"></span>
        {{ 'share' | umbracoTranslate | async }}
      </button> -->
    </div>
    <!-- <img
      class="image-sec"
      [src]="MediaUrl + newsContentDto.image?.src"
      [alt]="newsContentDto.image?.name"
    /> -->
    <div
      class="desc"
      [innerHTML]="newsContentDto.details | sanitizedHtml"
    ></div>
    <div class="tags-list">
      @if (newsMetaDataContentDto) { @for (item of
      newsMetaDataContentDto.metaTags; track $index) {
      <span class="tag">{{ item }}</span>
      } }
    </div>
    }
    </div>
    <div class="interesting-news" [class]="relatedNewsDto.length == 0 ?'no-news':''">
        <span class="title">{{ 'interestingNews' | umbracoTranslate | async }}</span>
        <button class="btn all-news" [routerLink]='["../"]'>{{ 'allNews' | umbracoTranslate | async }}</button>
    </div>
<app-another-news [newsItemDto]="relatedNewsDto" [class]="relatedNewsDto.length == 0 ?'no-another-news':''"></app-another-news>

</div>
<!-- Modal -->
<div class="modal fade" id="share" tabindex="-1" aria-labelledby="shareLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered">
    <div class="modal-content">
      <div class="modal-header justify-content-between">
        <h1 class="modal-title fs-5" id="share">{{ 'share' | umbracoTranslate | async }}  </h1>
        <button type="button" class="close-btn" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <share-buttons [theme]="'modern-dark'"
 [include]="['facebook','x','linkedin','email','copy']"
 [showText]="true"
 [url]="currentUrl"

></share-buttons>
      </div>
    </div>
  </div>
</div>
