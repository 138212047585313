import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { ContentPagesServices } from '../../services/content-page-services.service';
import {
  CommonContentService,
  DetailsWidgetDto,
  IntroDto,
  SuppliersPortalContentService,
} from '../../../api/Client';
import { SanitizedHtmlPipe } from '../../core/pipes/sanitized-html.pipe';
import { environment } from '../../../environments/environment';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';
import { PageTitleInfoComponent } from '../../components/shared/page-title-info/page-title-info.component';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-suppliers',
  standalone: true,
  imports: [SanitizedHtmlPipe,PageTitleInfoComponent,NgOptimizedImage],
  templateUrl: './suppliers.component.html',
  styleUrl: './suppliers.component.scss',
  animations: [fadeInAnimation],
})
export class SuppliersComponent implements OnInit, OnDestroy {
  supplierDetailsDto = {} as DetailsWidgetDto;
  public IntroDto!: IntroDto;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  constructor(
    private _client: SuppliersPortalContentService,
    private _contentPagesServices: ContentPagesServices,
    private _common: CommonContentService,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}
  ngOnInit(): void {
    var url = this._contentPagesServices.getPathForCurrentPage();
    this._common.introText(url).subscribe((res) => {
      this.IntroDto = res;
      console.log(this.IntroDto);
      
      this._cotnentLoadingService.setLoadingState(true);
    });
    this._client.details(url).subscribe({
      next: (res) => {
        this.supplierDetailsDto = res;
        this._cotnentLoadingService.setLoadingState(true);
      },
    });
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
