import {
  Component,
  Inject,
  OnDestroy,
  OnInit,
  Optional,
  PLATFORM_ID,
  REQUEST,
} from '@angular/core';
import {
  ActivatedRoute,
  Router,
  RouterModule,
  RouterStateSnapshot,
} from '@angular/router';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { NgxSpinnerComponent, NgxSpinnerService } from 'ngx-spinner';
import {
  CommonModule,
  DOCUMENT,
  isPlatformBrowser,
  isPlatformServer,
} from '@angular/common';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { UmbracoTranslatePipe } from '../../core/pipes/umbraco-translate.pipe';
import { FormsModule } from '@angular/forms';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';
import { Meta, Title } from '@angular/platform-browser';
import { CommonContentService, SeoDto } from '../../../api/Client';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Console } from 'console';
import { timeout } from 'rxjs';

@Component({
  selector: 'app-not-found',
  standalone: true,
  imports: [
    NgxSpinnerComponent,
    CommonModule,
    RouterModule,
    UmbracoTranslatePipe,
    FormsModule,
  ],
  templateUrl: './not-found.component.html',
  styleUrl: './not-found.component.scss',
  animations: [fadeInAnimation],
})
export class NotFoundComponent implements OnInit, OnDestroy {
  public seoDto: SeoDto | null = null;
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _contentPagesServices: ContentPagesServices,
    private spinner: NgxSpinnerService,
    private _cotnentLoadingService: CotnentLoadingService,
    private title: Title,
    private metaData: Meta,
    private _client: CommonContentService,
    @Inject(PLATFORM_ID) private platformId: object,
    @Inject(DOCUMENT) private document: any,
    private http: HttpClient
  ) {
    //this.spinner.show();
  }
  showNotFound: boolean = false;
  term: string = '';
  public CurrentLanguage: string | null = 'ar';
  currentUrl: string = '';
  private apiUrl = '/api/app/common-content/s-eOInfo?path=';

  ngOnInit() {
    if (
      isPlatformServer(this.platformId) &&
      this.document &&
      this.document.location &&
      this.document.location.pathname
    ) {
      console.log('updateSeoTags enter');
      this.currentUrl = this.document.location.pathname
        .replace('/ar/', '')
        .replace('/en/', '');

      const headers = new HttpHeaders().set('Accept-Language', 'ar');
      this.http
        .get(
          environment.apis.default.backToBackUrl +
            this.apiUrl +
            this.currentUrl,
          {
            headers,
          }
        ).pipe(timeout(1000))
        .subscribe({
          next: (result) => {
            this.title.setTitle((result as any).title ?? '');
            this.seoDto = result as any;
            this.updateSeoTags(this.seoDto!, this.document.location.href);
          },
          error: (error) => {
            console.log('Error SEO... ');
            console.log(error);
          },
        });
    }

    if (isPlatformBrowser(this.platformId)) {
      this.activatedRoute.data.subscribe(({ customRoutes }) => {
        var currentPagePath =
          this._contentPagesServices.getPathForCurrentPage(false);
        var targetPath = customRoutes.filter(
          (x: any) => '/' + x.path == currentPagePath
        )[0];
        if (targetPath !== undefined) {
          this.router.navigate([targetPath.path]);
        } else {
          this.showNotFound = true;
        }
        //this.spinner.hide();
        this._cotnentLoadingService.setLoadingState(true);
      });
    }
  }
  ngOnDestroy(): void {
    // this._cotnentLoadingService.setLoadingState(false);
  }

  // private getPathForCurrentPage(routerState: RouterStateSnapshot) {
  //   return decodeURIComponent(
  //     routerState.url.replace('/ar/', '').replace('/en/', '')
  //   );
  // }

  private updateSeoTags(seoDto: SeoDto, routerState: string) {
    this.metaData.updateTag({
      name: 'title',
      content: this.seoDto?.title ?? '',
    });
    this.metaData.updateTag({
      name: 'description',
      content: this.seoDto?.description ?? '',
    });

    this.metaData.updateTag({
      name: 'keywords',
      content: this.seoDto?.metaTags?.join(',') ?? '',
    });
    this.metaData.updateTag({
      property: 'og:title',
      content: this.seoDto?.ogTitle ?? '',
    });

    this.metaData.updateTag({
      property: 'og:description',
      content: this.seoDto?.ogDescription ?? '',
    });

    this.metaData.updateTag({
      property: 'og:image',
      content:
        this.MediaUrl +
        (this.seoDto?.ogImage !== null ? this.seoDto?.ogImage?.src : ''),
    });

    this.metaData.updateTag({
      property: 'og:url',
      content:
        this.document.location.origin +
        decodeURIComponent(this.document.location.href),
    });

    this.metaData.updateTag({
      name: 'twitter:card',
      content: this.seoDto?.xCard ?? '',
    });

    this.metaData.updateTag({
      name: 'twitter:title',
      content: this.seoDto?.xTitle ?? '',
    });
    this.metaData.updateTag({
      name: 'twitter:description',
      content: this.seoDto?.xDescription ?? '',
    });
    this.metaData.updateTag({
      name: 'twitter:image',
      content:
        this.MediaUrl +
        (this.seoDto?.xImage !== null ? this.seoDto?.xImage?.src ?? '' : ''),
    });
    this.metaData.updateTag({
      name: 'twitter:image:alt',
      content:
        this.seoDto?.xImage !== null ? this.seoDto?.xImage?.name ?? '' : '',
    });
  }
}
