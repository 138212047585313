import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { CommonContentService } from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { SanitizedHtmlPipe } from '../../core/pipes/sanitized-html.pipe';
import { PageRatingComponent } from '../../components/shared/page-rating/page-rating.component';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-terms-conditions',
  standalone: true,
  imports: [CommonModule, SanitizedHtmlPipe, PageRatingComponent],
  templateUrl: './terms-conditions.component.html',
  styleUrl: './terms-conditions.component.scss',
  animations: [fadeInAnimation],
})
export class TermsConditionsComponent implements OnInit , OnDestroy {
  content: string = '';
  constructor(
    private _client: CommonContentService,
    private _contentPagesServices: ContentPagesServices,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}
  ngOnInit(): void {
    var url = this._contentPagesServices.getPathForCurrentPage();
    this._client.wYSIWYG(url).subscribe({
      next: (respone) => {
        this.content = respone['cotnent'];
        this._cotnentLoadingService.setLoadingState(true);
      },
    });
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
