<div class="news-filter-container">
  <div class="filter-sec row">
    <div class="filter-input-sec col-md-12 col-lg-7">
      <span class="icon-search-input"></span>
      <input
        class="input filter"
        type="text"
        placeholder="{{ 'searchNews' | umbracoTranslate | async }}"
        #debounceInput
        [(ngModel)]="SearchTerm"
        (keyup.enter)="Search($event)"
      />
    </div>
    @if (false) {
    <select
      [(ngModel)]="newsTypeId"
      (change)="onOptionChange($event)"
      class="dropdown custom-select col-12 col-lg-2"
    >
      <option [value]="null" selected>
        {{ "newType" | umbracoTranslate | async }}
      </option>
      <option *ngFor="let item of NewsTypeDto; let i = index" [value]="item.id">
        {{ item.name }}
      </option>
    </select>
  }
    <button (click)="Search($event)" class="search-btn col-lg-2" [class]="false ? 'col-4' : 'col-12'">
      {{ "search" | umbracoTranslate | async }}
    </button>
  </div>
  <div class="news-container">
    <div class="important-news row grid gap-0 row-gap-lg-5">
      <div class="main-news col-lg-8 mb-2">
        @if (selectedNews) {
        <img
          [ngSrc]="MediaUrl + selectedNews.image?.src"
          width="612"
          height="612"
          [alt]="selectedNews.image?.name"
          class="main-news-img"
        />
        <div class="caption">
          <div class="news-info">
            <!-- <span class="news-category">{{ selectedNews.category?.name }}</span> -->
            <div class="news-date">
              <span class="date-icon"></span>
              {{ selectedNews.date | date : "dd/MM/yyyy" }}
            </div>
          </div>
          <h6 class="news-title">{{ selectedNews.title }}</h6>
          <p class="news-text">{{ selectedNews.description }}</p>
          <button
            class="btn more-news"
            [routerLink]="selectedNews.clickToAction?.url"
          >
            {{ "more" | umbracoTranslate | async }}
            <span class="arrow-more"></span>
          </button>
        </div>
        }
      </div>
      <div class="sub-main-news-list col-lg-4">
        @if (FeatureNewsDto) { @for (item of FeatureNewsDto; track $index) {
        <div
          class="sub-news-sec"
          [class.active]="selectedNews === item"
          (click)="selectedNews = item"
        >
          <div class="news-info">
            <div class="news-tags">
              <!-- <span class="news-category">{{ item.category?.name }}</span> -->
              <div class="news-date">
                <span class="date-icon-green"></span>
                {{ item.date | date : "dd/MM/yyyy" }}
              </div>
            </div>
            <div class="news-desc">
              <span class="news-title">{{ item.title }}</span>
              <span class="news-text">{{ item.description }}​​</span>
            </div>
          </div>
        </div>
        } }
      </div>
    </div>
    <app-another-news [newsItemDto]="NewsItemDto"></app-another-news>
    <div class="pagination-sec">
      <button
        (click)="LoadMore($event)"
        *ngIf="CurrentCount < TotalCount"
        class="btn more"
      >
        {{ "moreNews" | umbracoTranslate | async }}
      </button>
    </div>
  </div>
</div>
