import { Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { environment } from '../../../environments/environment';
import {
  CommonContentService,
  IntroDto,
  JoinUsDto,
  JoinUsPageContentService,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { forkJoin } from 'rxjs';
import { PageTitleInfoComponent } from '../../components/shared/page-title-info/page-title-info.component';
import { ListAnimationDirective } from '../../core/pipes/list-animation.directive';
import { PageRatingComponent } from '../../components/shared/page-rating/page-rating.component';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-hiring',
  standalone: true,
  imports: [
    PageTitleInfoComponent,
    ListAnimationDirective,
    NgOptimizedImage,
    PageRatingComponent,
    CommonModule,
  ],
  templateUrl: './hiring.component.html',
  styleUrl: './hiring.component.scss',
  animations: [fadeInAnimation],
})
export class HiringComponent implements OnInit, OnDestroy {
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public IntroDto!: IntroDto;
  public JoinUsContentDto!: JoinUsDto;
  constructor(
    private _client: JoinUsPageContentService,
    private _common: CommonContentService,
    private _contentPagesServices: ContentPagesServices,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}
  ngOnInit(): void {
    var currentPagePath = this._contentPagesServices.getPathForCurrentPage();
    forkJoin({
      intoDto: this._common.introText(currentPagePath),
      joinUsContentDto: this._client.content(currentPagePath),
    }).subscribe(async ({ intoDto, joinUsContentDto }) => {
      this.IntroDto = intoDto;
      this.JoinUsContentDto = joinUsContentDto;
      this._cotnentLoadingService.setLoadingState(true);
    });
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
