import { CommonModule, isPlatformBrowser, NgOptimizedImage } from '@angular/common';
import { Component, effect, Inject, PLATFORM_ID } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { NavigationService } from '../../services/navigation.service';
import { environment } from '../../../environments/environment';
import {
  CommonContentService,
  FooterSectionDto,
  SocialLinksDto,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { filter } from 'rxjs';
import { UmbracoTranslatePipe } from '../../core/pipes/umbraco-translate.pipe';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [CommonModule, RouterModule, UmbracoTranslatePipe, NgOptimizedImage],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  constructor(
    private _client: CommonContentService,
    public navigation: NavigationService,
    private _router: Router,
    private _contentPagesServices: ContentPagesServices,
    private _cotnentLoadingService: CotnentLoadingService,
    @Inject(PLATFORM_ID) private platformId: any,
  ) {
    effect(() => {
      this.showFooter = this._cotnentLoadingService.fullyLoadedState();
    });
  }
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  public CurrentLanguage: string | null = 'ar';
  public urls: Record<string, string> | undefined;
  public showFooter: boolean = false;
  private fontScale: number = 100; // Start with 100% font size
  footerSectionDto = {} as FooterSectionDto;
  socialSectionDto = {} as SocialLinksDto;

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.CurrentLanguage = localStorage.getItem('current-lang');
      this._client.footerWidget().subscribe((section) => {
        this.footerSectionDto = section;

        if (
          window.location.pathname.includes('/en/') &&
          !this.footerSectionDto.enableEnglishLanguage
        ) {
          localStorage.setItem('current-lang', 'ar');
          window.location.href = '/ar';
        }
      });
      this._client.socialLinksWidget().subscribe((section) => {
        this.socialSectionDto = section;
      });

      this._router.events
        .pipe(filter((event) => event instanceof NavigationEnd))
        .subscribe(async (event: NavigationEnd) => {
          var pageUrl =
            await this._contentPagesServices.getPathForCurrentPage();
          this._client.pageUrls(pageUrl).subscribe({
            next: (result) => {
              this.urls = result;
            },
          });
        });
    }
  }
  public swapLanguages() {
    if (this.CurrentLanguage === 'en-US') {
      localStorage.setItem('current-lang', 'ar');

      location.href = this.urls!['/ar'] ?? '/ar';
    }
    if (this.CurrentLanguage === 'ar') {
      localStorage.setItem('current-lang', 'en-US');

      location.href = this.urls!['/en'] ?? '/en';
    }
  }
  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  // Method to increase font size by a percentage
  increaseFontSize() {
    this.fontScale += 10; // Increase font size by 10%
    this.updateFontSize();
  }

  // Method to decrease font size by a percentage
  decreaseFontSize() {
    if (this.fontScale > 50) {
      // Prevent font size from becoming too small
      this.fontScale -= 10; // Decrease font size by 10%
      this.updateFontSize();
    }
  }

  // Update the root font size
  private updateFontSize() {
    document.documentElement.style.setProperty(
      '--base-font-size',
      `${this.fontScale}%`
    );
    console.log(
      document.documentElement.style.getPropertyValue('--base-font-size')
    );
  }
}
