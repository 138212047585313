import { Component, Input } from '@angular/core';
import { AnotherNewsComponent } from '../another-news/another-news.component';
import { environment } from '../../../../environments/environment';
import { NewsDetailsDto, SeoDto } from '../../../../api/Client';
import { SanitizedHtmlPipe } from '../../../core/pipes/sanitized-html.pipe';
import { CommonModule } from '@angular/common';
import { Router, RouterModule } from '@angular/router';
import { UmbracoTranslatePipe } from '../../../core/pipes/umbraco-translate.pipe';
import { ShareButtons } from 'ngx-sharebuttons/buttons';

@Component({
  selector: 'app-news-details',
  standalone: true,
  imports: [AnotherNewsComponent,SanitizedHtmlPipe,CommonModule, UmbracoTranslatePipe,RouterModule,ShareButtons],
  templateUrl: './news-details.component.html',
  styleUrl: './news-details.component.scss'
})
export class NewsDetailsComponent {
  /**
   *
   */
  public MediaUrl: string = environment.apis.mediaDeliveryAPI.url;
  currentUrl = ''
  constructor(private router: Router) {
    this.currentUrl = `${environment.Base_APP_URL}${decodeURIComponent(this.router.url)}`
  }

  @Input()relatedNewsDto: NewsDetailsDto[] = []
  @Input()newsMetaDataContentDto!: SeoDto
  @Input()newsContentDto!: NewsDetailsDto

}
