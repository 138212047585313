import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeInAnimation } from '../../core/pipes/router-transition-animation';
import { ListAnimationDirective } from '../../core/pipes/list-animation.directive';
import { PageTitleInfoComponent } from '../../components/shared/page-title-info/page-title-info.component';
import {
  CommonContentService,
  IntroDto,
  PagesBasicInfoDto,
} from '../../../api/Client';
import { ContentPagesServices } from '../../services/content-page-services.service';
import { TreeModule } from 'primeng/tree';
import { TreeNode } from 'primeng/api';
import { RouterModule } from '@angular/router';
import { UmbracoTranslatePipe } from '../../core/pipes/umbraco-translate.pipe';
import { CotnentLoadingService } from '../../services/cotnent-loading.service';
@Component({
  selector: 'app-site-map',
  standalone: true,
  imports: [
    RouterModule,
    ListAnimationDirective,
    PageTitleInfoComponent,
    TreeModule,
    CommonModule,
    UmbracoTranslatePipe,
  ],
  templateUrl: './site-map.component.html',
  styleUrl: './site-map.component.scss',
  animations: [fadeInAnimation],
})
export class SiteMapComponent implements OnInit, OnDestroy {
  constructor(
    private _common: CommonContentService,
    private _contentPagesServices: ContentPagesServices,
    private cd: ChangeDetectorRef,
    private _cotnentLoadingService: CotnentLoadingService
  ) {}
  public IntroDto: IntroDto | undefined;
  loading: boolean = false;
  pagesBasicInfoDto: PagesBasicInfoDto[] = [];
  nodes2!: TreeNode[];

  ngOnInit(): void {
    var currentPath = this._contentPagesServices.getPathForCurrentPage();

    this.loading = true;
    this._common.introText(currentPath).subscribe({
      next: (respone) => {
        this.IntroDto = respone;
        this._cotnentLoadingService.setLoadingState(true);
      },
    });

    this._common.children('/').subscribe({
      next: (respone) => {
        this.pagesBasicInfoDto = respone;
        this.nodes2 = this.initiateNodes2(this.pagesBasicInfoDto);
        this.loading = false;
        this.nodes2.map((node) => (node.loading = false));
        window.scrollBy({ top: +50, behavior: 'smooth' });
        this.cd.markForCheck();
      },
    });
  }

  initiateNodes2(data: PagesBasicInfoDto[]): TreeNode[] {
    var items: TreeNode[] = [];
    data.forEach((x) => {
      items.push({
        label: x.name,
        leaf:
          x.count == 0 ||
          x.alias == 'home' ||
          x.alias == 'mediaVideo' ||
          x.alias == 'faqs' ||
          x.alias == 'mediaImage'||
          x.alias == 'mediaLibrary',
        key: x.url,
        type: 'url',
        children: [],
      });
    });
    return items;
  }

  onNodeExpand2(event: any) {
    event.node.loading = true;
    event.originalEvent.target.classList.add('collapse-icon');
    let _node = event.node;
    this._common.children(_node.key).subscribe({
      next: (respone) => {
        this.pagesBasicInfoDto = respone;
        _node.children = this.initiateNodes2(this.pagesBasicInfoDto);
        window.scrollBy({ top: +50, behavior: 'smooth' });
        this.loading = false;
        _node.loading = false;
        this.cd.markForCheck();
      },
    });
  }
  onNodeCollapse(event: any) {
    event.originalEvent.target.classList.remove('collapse-icon');
  }
  ngOnDestroy(): void {
    this._cotnentLoadingService.setLoadingState(false);
  }
}
